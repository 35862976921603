import React, { Fragment, useState } from "react";
import { Tabs } from "../components/Tabs/Tabs";
import { OrderFormStepOne } from "../components/Forms/OrderFormStepOne";
import { OrderFormStepTwo } from "../components/Forms/OrderFormStepTwo";
import { OrderFormStepThree } from "../components/Forms/OrderFormStepThree";
import { OrderFormStepSuccess } from "../components/Forms/OrderFormStepSuccess";
import { scrollToTop } from "../utils/utils";
import validator from "validator";
import axios from "axios";
import ReactDOM from "react-dom";

export const OrderPage = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    position: "",
    email: "",
    phone: "",
    businessDescription: "",
    linkToRemove: "",
    linkToOriginal: "",
    isProtected: "no",
    regNumber: "",
    jurisdiction: "",
    file: null,
    issue: "",
  });

  const fieldNameMap = {
    firstName: "First Name",
    lastName: "Last Name",
    organization: "Organization",
    position: "Position",
    email: "Email",
    phone: "Phone",
    businessDescription: "Business Description",
    linkToRemove: "Removal Link",
    linkToOriginal: "Original Link",
    isProtected: "Protected",
    regNumber: "Registration Number",
    jurisdiction: "Jurisdiction",
    file: "File",
    issue: "Issue",
  };

  const [formValidation, setFormValidation] = useState({});
  const [errors, setErrors] = useState({});

  const handleCurrentTab = (number) => {
    if (validateCurrentStep()) {
      setCurrentTab(number);
      scrollToTop();
    }
  };

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    validateField(name, value);
  };

  const handleFormSelectChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    validateField(name, value);
  };

  const handleFileChange = (file) => {
    setFormData({
      ...formData,
      file: file,
    });
  };

  const validateField = (name, value) => {
    let isValid = false;
    let error = "";

    switch (name) {
      case "firstName":
        if (validator.isEmpty(value)) {
          error = "First name is required";
        } else {
          isValid = true;
        }
        break;
      case "lastName":
        if (validator.isEmpty(value)) {
          error = "Last name is required";
        } else {
          isValid = true;
        }
        break;
      case "organization":
        if (validator.isEmpty(value)) {
          error = "Organization/Company name is required";
        } else {
          isValid = true;
        }
        break;
      case "businessDescription":
        if (validator.isEmpty(value)) {
          error = "Business description is required";
        } else {
          isValid = true;
        }
        break;
      case "linkToRemove":
        if (!validator.isURL(value)) {
          error = "Invalid URL format for link to remove";
        } else if (validator.isEmpty(value)) {
          error = "Link to the content you wish to remove is required";
        } else {
          isValid = true;
        }
        break;
      case "linkToOriginal":
        if (!validator.isURL(value)) {
          error = "Invalid URL format for link to original";
        } else if (validator.isEmpty(value)) {
          error = "Link to original/official online presence is required";
        } else {
          isValid = true;
        }
        break;
      case "issue":
        if (value.length < 40) {
          error = "Issue description is too short";
        } else if (validator.isEmpty(value)) {
          error = "Issue description is required";
        } else {
          isValid = true;
        }
        break;
      case "email":
        if (!validator.isEmail(value)) {
          error = "Invalid email address";
        } else if (validator.isEmpty(value)) {
          error = "Email address is required";
        } else {
          isValid = true;
        }
        break;
      case "phone":
        if (!validator.isNumeric(value)) {
          error = "Invalid phone number";
        } else if (validator.isEmpty(value)) {
          error = "Phone number is required";
        }
        break;
      case "isProtected":
        isValid = value !== null;
        if (!isValid) {
          error = "Please select if the content is protected";
        }
        break;
      case "regNumber":
        if (!validator.isNumeric(value)) {
          error = "Invalid registration number";
        } else if (formData.isProtected === "yes" && validator.isEmpty(value)) {
          error = "Registration number is required";
        } else {
          isValid = true;
        }
        break;
      case "jurisdiction":
        if (formData.isProtected === "yes" && validator.isEmpty(value)) {
          error = "Jurisdiction is required";
        } else {
          isValid = true;
        }
        break;
      default:
        break;
    }

    setFormValidation({
      ...formValidation,
      [name]: isValid,
    });
    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const validateCurrentStep = () => {
    let isValid = true;
    let currentErrors = {};

    switch (currentTab) {
      case 1:
        ["firstName", "lastName", "organization", "email"].forEach((field) => {
          if (!formValidation[field]) {
            isValid = false;
            if (!errors[field] && validator.isEmpty(formData[field])) {
              currentErrors[field] = `${fieldNameMap[field]} is required`;
            }
          }
        });
        break;
      case 2:
        ["linkToRemove", "linkToOriginal", "issue"].forEach((field) => {
          if (!formValidation[field]) {
            isValid = false;
            if (!errors[field] && validator.isEmpty(formData[field])) {
              currentErrors[field] = `${fieldNameMap[field]} is required`;
            }
          }
        });
        if (formData.isProtected === "yes") {
          ["regNumber", "jurisdiction"].forEach((field) => {
            if (!formValidation[field]) {
              isValid = false;
              if (!errors[field] && validator.isEmpty(formData[field])) {
                currentErrors[field] = `${fieldNameMap[field]} is required`;
              }
            }
          });
        }
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      ...currentErrors,
    });
    return isValid;
  };

  const handleFormFinish = async (e) => {
    e.preventDefault();
    if (validateCurrentStep()) {
      try {
        const formResult = new FormData();
        formResult.append("status", "pending");
        formResult.append("firstname", formData.firstName);
        formResult.append("lastname", formData.lastName);
        formResult.append("organization", formData.organization);
        formResult.append("position", formData.position);
        formResult.append("email", formData.email);
        formResult.append("phone", formData.phone);
        formResult.append("businessDescription", formData.businessDescription);
        formResult.append("linkToRemove", formData.linkToRemove);
        formResult.append("linkToOriginal", formData.linkToOriginal);
        formResult.append("isProtected", formData.isProtected);
        formResult.append("regNumber", formData.regNumber);
        formResult.append("jurisdiction", formData.jurisdiction);
        formResult.append("issue", formData.issue);
        formResult.append("file", formData.file); // Assuming formData.file contains the file object
        formResult.append("ip", ""); // Add IP if needed
        formResult.append("payment_status", "pending");
        formResult.append("payment_sum", 500);

        const response = await axios.post("/api/orders", formResult, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data && response.data.id) {
          // handle success (e.g., show success message, navigate to another page, etc.)
          const firstScript = document.createElement("script");
          firstScript.src =
            "https://www.googletagmanager.com/gtag/js?id=AW-16731909270";
          firstScript.async = true;

          document.body.appendChild(firstScript);

          const script = document.createElement("script");

          script.textContent = `
            window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-16731909270');
  
          gtag('event', 'conversion', {'send_to': 'AW-16731909270/ImRGCMTpzdsZEJbZsqo-'});`;
          script.async = true;
          document.body.appendChild(script);
          handleCurrentTab(4);
        }
      } catch (error) {
        console.error("Error submitting the form", error);
        // handle error (e.g., show error message)
      }
    }
  };

  const renderCurrentTabForm = () => {
    switch (currentTab) {
      case 1:
        return (
          <OrderFormStepOne
            changeTab={handleCurrentTab}
            formData={formData}
            handleChange={handleFormDataChange}
            errors={errors}
          />
        );
      case 2:
        return (
          <OrderFormStepTwo
            changeTab={handleCurrentTab}
            formData={formData}
            handleChange={handleFormDataChange}
            handleSelectChange={handleFormSelectChange}
            errors={errors}
            onFileChange={handleFileChange}
          />
        );
      case 3:
        return (
          <OrderFormStepThree
            changeTab={handleCurrentTab}
            formData={formData}
            errors={errors}
            fieldNameMap={fieldNameMap}
            handleFormFinish={handleFormFinish}
          />
        );
      case 4:
        return <OrderFormStepSuccess />;
      default:
        return <OrderFormStepOne />;
    }
  };

  return (
    <Fragment>
      <Tabs currentTab={currentTab} />
      {renderCurrentTabForm()}
    </Fragment>
  );
};
