import { FormWrapper } from "./FormWrapper";
import { Button } from "../basic/Button";
import { arrowRight } from "../../static/images";
import { navigateHelper } from "../../utils/utils";
import { Link } from "react-router-dom";
import { ROUTE_TERMS_OF_USE } from "../../routes/routes";

export const OrderFormStepThree = ({
  changeTab,
  formData,
  fieldNameMap,
  handleFormFinish,
}) => {
  const renderFormData = () => {
    if (!formData || !fieldNameMap) {
      return null;
    }

    return (
      <div className="form-data-table double-column">
        <div>
          {Object.keys(fieldNameMap).map((key) => {
            if (fieldNameMap[key]) {
              return (
                <div key={key} className="form-data-row">
                  <div className="form-data-label">
                    <strong>{fieldNameMap[key]}</strong>
                  </div>
                  <div className="form-data-value">
                    {key === "file" && formData["file"]
                      ? formData["file"].name
                      : formData[key]
                        ? formData[key]
                        : "Not provided"}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  const renderCostDetails = () => {
    const costDetails = [
      {
        service: "Takedown Request",
        details: "Removal of specified content",
        cost: "$500",
      },
      {
        service: "Location-Based Fee",
        details: "Applied based on user's location",
        cost: "Varies",
      },
    ];

    return (
      <div className="form-data-table triple-column">
        <div>
          {costDetails.map((item, index) => (
            <div key={index} className="form-data-row">
              <div className="form-data-label">
                <strong>{item.service}</strong>
              </div>
              <div className="form-data-value">{item.details}</div>
              <div className="form-data-cost">{item.cost}</div>
            </div>
          ))}
          <div
            key={costDetails.length}
            className="form-data-row-last form-data-row"
            height="80px"
          >
            <div className="form-data-label">
              <strong>Total Estimated Cost</strong>
            </div>
            <div className="form-data-value empty"></div>
            <div className="form-data-cost">
              <strong>$500+</strong>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FormWrapper size={"md"} title={`Content Removal Request Form`}>
      <p className={`form-wrapper-subtitle subtitle lg`}>
        Providing this information authorizes Fortified-Web to generate a Letter
        of Authorization, which will be sent to service providers. Details can
        be found in our{" "}
        <Link onClick={() => navigateHelper()} to={ROUTE_TERMS_OF_USE}>
          Terms of Use
        </Link>
      </p>
      <form
        id={`contact-form-step-summary`}
        onSubmit={handleFormFinish}
        action={undefined}
      >
        <div className={`form`}>
          <h5 className={`project-item-title gradient-title`}>
            Please review the information provided to ensure accuracy
          </h5>
          <div className={`form-summary-inner`}>{renderFormData()}</div>
        </div>
        <br />
        <div className={`form`}>
          <div className={`form-summary-inner`}>
            Once you confirm and submit your request, our team will assess the
            details. If your request is approved, we will contact you via email
            to discuss payment arrangements.
          </div>
          <div className={`form-summary-inner`}>{renderCostDetails()}</div>

          <div className={`form-buttons-wrapper`}>
            <Button
              onClick={() => changeTab(2)}
              type={"button"}
              size={"md"}
              btnStyle={"secondary"}
            >
              <img
                style={{ transform: `rotate(180deg)` }}
                src={arrowRight}
                alt="arrow right"
              />{" "}
              Back
            </Button>
            <Button type={"submit"} size={"lg"} btnStyle={"primary"}>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </FormWrapper>
  );
};
