import { FormInput } from "../basic/FormComponents/FormInput";
import { Button } from "../basic/Button";
import { Link } from "react-router-dom";
import { ROUTE_PRIVACY_POLICY, ROUTE_TERMS_OF_USE } from "../../routes/routes";
import { FormWrapper } from "./FormWrapper";
import { useState } from "react";
import { FormTextarea } from "../basic/FormComponents/FormTextarea";
import { navigateHelper } from "../../utils/utils";
import { successFormV1 } from "../../static/images";
import axios from "axios";

export const ContactForm = () => {
  const validator = require("validator");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    business_email: "",
    phone: "",
    message: "",
    submit: false,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    let error = "";

    switch (name) {
      case "firstName":
        if (value.trim() === "") {
          error = "First name is required";
        }
        break;
      case "lastName":
        if (value.trim() === "") {
          error = "Last name is required";
        }
        break;
      case "business_email":
        if (value.trim() === "") {
          error = "Email is required";
        } else if (!validator.isEmail(value)) {
          error = "Email is invalid";
        }
        break;
      case "phone":
        if (value.trim() === "") {
          error = "Phone number is required";
        } else if (!validator.isNumeric(value)) {
          error = "Phone number is invalid";
        }
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const scrollToFormTop = () => {
    const element = document.getElementById("contact");
    element?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const validateForm = () => {
    const hasErrors = Object.values(errors).some((error) => error);
    const requiredFieldsFilled =
      formData.firstName.trim() !== "" &&
      formData.lastName.trim() !== "" &&
      formData.business_email.trim() !== "";
    if (hasErrors || !requiredFieldsFilled) {
      scrollToFormTop();
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure mandatory fields are filled
    const newErrors = {
      firstName:
        formData.firstName.trim() === "" ? "First name is required" : "",
      lastName: formData.lastName.trim() === "" ? "Last name is required" : "",
      business_email:
        formData.business_email.trim() === ""
          ? "Email is required"
          : !validator.isEmail(formData.business_email)
            ? "Email is invalid"
            : "",
    };

    setErrors(newErrors);

    if (validateForm()) {
      try {
        const response = await axios.post(
          "/api/contactus",
          {
            status: "new",
            firstname: formData.firstName,
            lastname: formData.lastName,
            email: formData.business_email,
            phone: formData.phone,
            message: formData.message,
            ip: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data && response.data.id) {
          setFormData({
            ...formData,
            submit: true,
          });
          console.log("Form submitted successfully");
        } else {
          console.error("Failed to submit form");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const ret = !formData.submit ? (
    <FormWrapper id={`contact`} size={`lg`} title={"Let’s Talk"}>
      <p className={`form-wrapper-subtitle subtitle lg`}>
        Need assistance or have a query? Drop us a line and we’ll be happy to
        assist!
      </p>
      <form className={`form`} id={`contact-form`} action={undefined}>
        <div className={`form-cols`}>
          <FormInput
            required
            name={`firstName`}
            placeholder={`Enter your first name `}
            type={`text`}
            label={`First name`}
            inputValue={formData.firstName}
            onChange={handleChange}
            isError={!!errors.firstName}
            error={errors.firstName}
          />
          <FormInput
            required
            name={`lastName`}
            placeholder={`Enter your last name`}
            type={`text`}
            label={`Last name`}
            inputValue={formData.lastName}
            onChange={handleChange}
            isError={!!errors.lastName}
            error={errors.lastName}
          />
        </div>
        <FormInput
          required
          name={`business_email`}
          placeholder={`Enter your email`}
          type={`email`}
          label={`Email`}
          inputValue={formData.business_email}
          onChange={handleChange}
          isError={!!errors.business_email}
          error={errors.business_email}
        />
        <FormInput
          name={`phone`}
          placeholder={`Enter your phone number`}
          type={`phone`}
          label={`Phone number`}
          inputValue={formData.phone}
          onChange={handleChange}
          isError={!!errors.phone}
          error={errors.phone}
        />
        <FormTextarea
          name={`message`}
          placeholder={`Enter your message here`}
          label={`Message`}
          onChange={handleChange}
          inputValue={formData.message}
          isError={!!errors.message}
          error={errors.message}
        />
        <p className={`form-message subtitle md`}>
          By submitting this form, you are agreeing to Fortified's{" "}
          <Link onClick={() => navigateHelper()} to={ROUTE_PRIVACY_POLICY}>
            privacy policy
          </Link>{" "}
          and{" "}
          <Link onClick={() => navigateHelper()} to={ROUTE_TERMS_OF_USE}>
            terms of use
          </Link>
          .
        </p>
        <Button
          type={"button"}
          onClick={(e) => handleSubmit(e)}
          size={"lg"}
          btnStyle={"primary"}
        >
          Submit
        </Button>
      </form>
    </FormWrapper>
  ) : (
    <FormWrapper size={"md"}>
      <div className={`form success v1`}>
        <div className={`success-image-wrapper`}>
          <img src={successFormV1} alt="success form" />
        </div>
        <div className={`form-success-inner`}>
          <p className={`success-title gradient-title`}>
            Thank you for reaching out!
          </p>
          <p className={`subtitle md`}></p>
          <p className={`subtitle md`}>
            Your message has been sent successfully.
            <br />
            We appreciate your interest and will respond as soon as possible{" "}
            <br />
            <br />
            For any urgent queries please contact us at{" "}
            <a href="mailto:info@fortified-web.com">info@fortified-web.com</a>
          </p>
        </div>
      </div>
    </FormWrapper>
  );

  return ret;
};
